import React, { useState, useRef, useEffect } from 'react';
import rightLineShap from '../../assets/images/right-line-shap.svg';
import './style.css'; // Optional: for CSS styling

const AccordionItem = ({ title, content, isOpen, onClick }) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    // Set the height based on whether the item is open or closed
    if (isOpen) {
      setHeight(contentRef.current.scrollHeight); // Get the full height of the content
    } else {
      setHeight(0); // Collapse the content
    }
  }, [isOpen]);

  return (
    <article className={`content-entry ${isOpen ? 'open' : ''}`}>
      <h4 className="article-title" onClick={onClick}>
        {title}
        <i><svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 13.5H25M13.5 25V2" stroke="#9AA2B5" stroke-width="3" stroke-linecap="round"/>
        </svg></i>
      </h4>
      <div
        className="accordion-content"
        ref={contentRef}
        style={{
          maxHeight: `${height}px`,
          overflow: 'hidden',
        }}
      >
        <p>{content}</p>
      </div>
    </article>
  );
};

const Accordion = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const accordionItems = [
    { title: 'How to take attendance online?', content: 'Taking attendance online is streamlined with modern student attendance management systems like MyRollCallPro with features such as real-time dashboards, automated verification, and customisable reporting. Teachers can initiate attendance by generating a unique code, which is shared with students. Students enter the code through their dashboard, and the system also captures a real-time selfie and verifies their location to ensure they are in proximity to the teacher. MyRollCallPro uses these advanced checks to ensure that attendance is accurate and efficiently recorded, reducing the need for manual roll calls. ' },
    { title: 'How to calculate the average attendance of students? ', content: 'To calculate the average attendance of students, you can follow a simple formula: Average Attendance (%) = (Number of Days Present / Total Number of Working Days) x 100 However, MyRollCallPro automates this process for you. The system generates detailed attendance reports that provide averages at the class, student, or institution level. This allows administrators to easily track attendance trends, identify patterns, and make informed decisions without manual calculations. ' },
    { title: 'What is a face recognition attendance system? ', content: 'A face recognition attendance system is an advanced method of marking attendance that uses biometric technology to identify students based on their facial features. The system captures images of students and compares them with stored data to verify their identity, eliminating the need for manual entry. This technology is highly secure and minimises fraudulent practices, ensuring accurate attendance records. Systems like MyRollCallPro incorporate face recognition to streamline the attendance process, making it efficient and secure for educational institutions.' },
    { title: 'What is a pin-based attendance system?', content: 'A pin-based attendance system allows students to mark their presence using a unique 2-digit code generated by the teacher. With MyRollCallPro, the teacher initiates the attendance session, generates a code, and shares it with the students. Students then input this code via their dashboard, accompanied by a real-time selfie and geo-location verification. Once all three checks—code, selfie, and location—are successfully matched, the student is marked present. This system ensures secure, fast, and accurate attendance logging.' },
    { title: 'How does the presence verification feature ensure accuracy? ', content: 'MyRollCallPro’s presence verification feature combines geo-location data and real-time facial recognition technology to ensure accurate attendance. The system checks whether the student s location matches the teacher s proximity and verifies the real-time selfie against the student’s ID. If any discrepancy is detected, an alert is sent to administrators, enabling them to intervene promptly. This multi-layered verification ensures a high level of accuracy and eliminates potential attendance errors. ' },
    { title: 'Is data safe on a student attendance management system?', content: 'Yes, modern student attendance management systems are designed with robust security measures to protect sensitive data. For example, MyRollCallPro is built with robust security measures to protect sensitive student data. The system uses encryption for both data in transit and at rest, ensuring that all personal information is stored and transmitted securely. Access to this data is restricted to authorised personnel only, and the platform complies with industry-standard privacy practices to safeguard against unauthorised access or data breaches. ' },
    { title: 'Is MyRollCallPro suitable for online, offline, or hybrid classes?', content: 'MyRollCallPro is designed to cater primarily to on-campus classes, where teachers and students are present in real time. It seamlessly supports attendance management for physical classrooms, making it ideal for institutions running in-person or hybrid models. MyRollCallPro eliminates the hassle of paperwork, allowing attendance to be marked swiftly in just 30 seconds through its pin-based system, geo-location and face recognition. ' },
  ];

  return (
    <section id='faq' className='section faq'>
      <div className='container'>
        <h2 className='section_heading'>Frequently Asked Questions</h2>
        <div id="accordion" className="accordion-container">
            {accordionItems.map((item, index) => (
            <AccordionItem
                key={index}
                title={item.title}
                content={item.content}
                isOpen={openIndex === index}
                onClick={() => handleToggle(index)}
            />
            ))}
        </div>
      </div>
      <span className='leftLineShap'><img src={rightLineShap}/></span>
    </section>
  );
};

export default Accordion;
