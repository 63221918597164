import React, { useState, useRef, useEffect } from 'react';
import './style.css'; // Assuming you're adding styles in this file
import logo from '../../assets/images/main-logo.svg';
import TopStrip from '../../top-strip';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const navRef = useRef(null);
  const location = useLocation(); // Get the current path

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target) && !event.target.closest('.mobile-menu-icon')) {
      closeMobileMenu();
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 50) { // Adjust the scroll position as needed
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`header ${isSticky ? 'sticky' : ''}`}>
      <TopStrip/>
      <div className="header-content">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>          
        </div>

        <nav ref={navRef} className={`nav ${isMobileMenuOpen ? 'open' : ''}`}>
          <ul className="nav-links">
              <li>
                <Link 
                  to="/pricing" 
                  className={location.pathname === '/pricing' ? 'active' : ''} 
                  onClick={closeMobileMenu}
                >
                  Pricing
                </Link>
              </li>
              <li>
                <Link 
                  to="/bloglist" 
                  className={location.pathname === '/bloglist' ? 'active' : ''} 
                  onClick={closeMobileMenu}
                >
                  Blogs
                </Link>
              </li>
              <li>
                <Link 
                  to="/bookademo" 
                  className={location.pathname === '/bookademo' ? 'active' : ''} 
                  onClick={closeMobileMenu}
                >
                  Book A Demo
                </Link>
              </li>              
          </ul>

          <Link to="/getapp" className="globel-button" onClick={closeMobileMenu}>
            <span>Get App</span>
            <i>
              <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6L16 6M16 6L11 1M16 6L11 11" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </i>
          </Link>
        </nav>

        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          <span className="menu-icon">
            <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none">
              <g id="Menu / Menu_Alt_05">
                <path id="Vector" d="M5 17H13M5 12H19M11 7H19" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
            </svg>
          </span>
        </div>
      </div>
    </header>
  );
};

export default Header;
