import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import videoImg from '../../assets/images/video-img.png';
import videoIcon from '../../assets/images/play-icon.png';
import staricon from '../../assets/images/star-icon.svg';
import crosicon from '../../assets/images/cross-icon.svg';
import downarrow from '../../assets/images/down-arrow.svg';
import videoImage from '../../assets/images/video-image.jpg';

import WOW from 'wowjs';

import './style.css';

function Banner() {
    const [modalClass, setModalClass] = useState('hide');

    const handleClose = () => {
        setModalClass('hide');
    };

    const handleClickOutside = (event) => {
        if (event.target.closest('.modal-content') !== document.getElementById('modal')) {
            setModalClass('hide');
        }
    };

    return (
        <>
            <div className='main-banner'>
                <span className='staricon'><img src={staricon} /></span>
                <h1 className="fade-up">
                    Transform Daily Attendance
                    <span>Fast, Accurate, Intelligent.</span>
                </h1>
                <h4>Maximise educational time, enhance student engagement, and elevate operational efficiency with MyRollCallPro’s advanced pin-based system.</h4>
                <h5>Save up to two months of curricular time annually with our proven methods.</h5>
                <div className='banner-btns'>
                    <a href='#faq' className="globel-button">
                        <span>Learn More</span>
                        <i>
                            <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 6L16 6M16 6L11 1M16 6L11 11" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </i>
                    </a>
                    <button className='play-video-btn' onClick={() => setModalClass('show')}>
                        <span className='video-icon'>
                            <img className='videoImg' src={videoImg} />
                            <img className='videoIcon' src={videoIcon} />
                        </span>
                        <h4>Watch How <br />It Works</h4>
                    </button>
                </div>
                <div className='crosicon'><img src={crosicon} /></div>
                <a href='#about' className='downarrow'><img src={downarrow} /></a>
            </div>

            <div id="modal" className={`modal-wrap ${modalClass}`}>
                <div className='overlay' onClick={handleClickOutside}></div>
                <div className="modal-content">
                    <div className='modal-header'>
                        <span className="close" onClick={handleClose}>
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 17L17 2" stroke="white" strokeWidth="3" strokeLinecap="round" />
                                <path d="M2 2.02588L17 17.0259" stroke="white" strokeWidth="3" strokeLinecap="round" />
                            </svg>
                        </span>
                        <h2>How it works</h2>
                    </div>
                    <div className='modal-body'>
                        <div className='video'>
                            <img src={videoImage} />
                            <a href="#" className="popup-youtube video-btn">
                                <i className="fas fa-play">
                                    <svg width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15 11L0 21.3923L0 0.607696L15 11Z" fill="#222E72" />
                                    </svg>
                                </i>
                            </a>
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <div className='col2'>
                            <div className='col6'>
                                <p>Unlock the full potential of MyRollCallPro and transform the way your institution handles attendance.</p>
                            </div>
                            <div className='col6'>
                                <div className='modal_buttons'>
                                    <button className='btn GetStarted'>Get Started</button>
                                    <Link to="/bookademo" className='btn RequestaDemo'>Request a Demo</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Banner;