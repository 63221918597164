// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-policy-content {
    margin: 0 0 170px 0;
}
.privacy-policy-content h3 {
    font-size: 24px;
}
.privacy-policy-content h5 {
    font-size: 18px;
    color: #136EF1;
    margin: 0;
}
.privacy-policy-content .options {
    padding: 0 0 0 20px;
}
.privacy-policy-content .options h4 {
    font-size: 18px;
}
.privacy-policy-content .options ul li {
    font-size: 18px;
    line-height: 25px;
}
.privacy-policy-content .options ul.number {
    list-style: auto;
}
.privacy-policy-content .options ul.number li::marker {
    font-weight: 600;
}
.privacy-policy-content .options ul.number li {
    margin: 0 0 10px 0px;
}



@media (max-width:767px){
    .privacy-policy-content {
        margin: 0 0 60px 0;
    }
    .privacy-policy-content h3 {
        font-size: 24px;
    }
    .innerpage-headerbanner {
        min-height: 180px !important;
        padding: 110px 0 0 0 !important;
    }
    .privacy-policy-content .options {
        padding: 0 0 0 0px;
    }
    .privacy-policy-content .options ul li {
        font-size: 16px;
        line-height: 25px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/privacypolicy/style.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;IACf,cAAc;IACd,SAAS;AACb;AACA;IACI,mBAAmB;AACvB;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,oBAAoB;AACxB;;;;AAIA;IACI;QACI,kBAAkB;IACtB;IACA;QACI,eAAe;IACnB;IACA;QACI,4BAA4B;QAC5B,+BAA+B;IACnC;IACA;QACI,kBAAkB;IACtB;IACA;QACI,eAAe;QACf,iBAAiB;IACrB;AACJ","sourcesContent":[".privacy-policy-content {\n    margin: 0 0 170px 0;\n}\n.privacy-policy-content h3 {\n    font-size: 24px;\n}\n.privacy-policy-content h5 {\n    font-size: 18px;\n    color: #136EF1;\n    margin: 0;\n}\n.privacy-policy-content .options {\n    padding: 0 0 0 20px;\n}\n.privacy-policy-content .options h4 {\n    font-size: 18px;\n}\n.privacy-policy-content .options ul li {\n    font-size: 18px;\n    line-height: 25px;\n}\n.privacy-policy-content .options ul.number {\n    list-style: auto;\n}\n.privacy-policy-content .options ul.number li::marker {\n    font-weight: 600;\n}\n.privacy-policy-content .options ul.number li {\n    margin: 0 0 10px 0px;\n}\n\n\n\n@media (max-width:767px){\n    .privacy-policy-content {\n        margin: 0 0 60px 0;\n    }\n    .privacy-policy-content h3 {\n        font-size: 24px;\n    }\n    .innerpage-headerbanner {\n        min-height: 180px !important;\n        padding: 110px 0 0 0 !important;\n    }\n    .privacy-policy-content .options {\n        padding: 0 0 0 0px;\n    }\n    .privacy-policy-content .options ul li {\n        font-size: 16px;\n        line-height: 25px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
