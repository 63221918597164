import React from 'react'
import { Link } from 'react-router-dom';
import footerLogo from '../../assets/images/RollCall.Logo-footer.svg';
import './style.css';
import FooterCTA from '../../components/footerCTA';

function Footer() {
  return (
    <>
    <FooterCTA/>
    <footer className='footer-section'>
        <div className='container col2'>
            <div className='footer-left col6'>
                <Link to="/"><img className='footerlogo' src={footerLogo} /></Link>
                <form className='newsletter-form'>
                    <h4>Stay Updated!</h4>
                    <div className='form-wrap'>
                        <input type='text' placeholder='Your Email' />
                        <button className='subscribe'>Subscribe</button>
                    </div>
                    <p>By clicking the button you consent to processing of your personal data</p>
                </form>
            </div>
            <div className='footer-right col2 col6'>
                <div className='col6'>
                    <div className='f_nav'>
                        <h3>Quick links</h3>
                        <nav>
                            <ul>
                                <li><Link to='/pricing'>Pricing</Link></li>
                                <li><Link to='/bloglist'>Blog</Link></li>
                                <li><Link to='/support'>Support</Link></li>
                                <li><Link to='/privacyplocy'>Terms of Service</Link></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className='col6'>
                    <div className='address'>
                        <h3>Contact info</h3>
                        <ul>
                            <li>
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.75 12.8309C23.75 16.3886 19.3467 22.2807 16.7875 25.4048C15.8511 26.5478 14.1489 26.5478 13.2125 25.4048C10.6533 22.2807 6.25 16.3886 6.25 12.8309C6.25 7.81565 10.1675 3.75 15 3.75C19.8325 3.75 23.75 7.81565 23.75 12.8309Z" stroke="#407BFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M18.2505 12.5001C18.2505 14.295 16.7954 15.7501 15.0005 15.7501C13.2056 15.7501 11.7505 14.295 11.7505 12.5001C11.7505 10.7051 13.2056 9.25007 15.0005 9.25007C16.7954 9.25007 18.2505 10.7051 18.2505 12.5001Z" stroke="#407BFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg> Gurugram, Delhi NCR, Haryana - 122002</li>
                            <li>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6333 19.3672C19.6781 28.4118 22.3283 26.2473 23.512 25.4018C23.7033 25.2932 28.0844 22.4702 25.3598 19.7461C19.0403 13.4262 20.3226 21.6408 14.3399 15.6592C8.35849 9.67641 16.5737 10.9596 10.2544 4.64034C7.52947 1.91547 4.70618 6.29681 4.5988 6.4869C3.75207 7.67063 1.58855 10.3225 10.6333 19.3672Z" stroke="#407BFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg> <a href='#'>+91-78145-72597</a></li>
                            <li>
                            <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.20579 12.4867L9.26139 13.473L13.4838 17.4182C14.3376 18.215 15.6625 18.215 16.5163 17.4182L21.806 12.4867M7.22222 24.3889H22.7777C24.005 24.3889 24.9999 23.394 24.9999 22.1667V11.0556C24.9999 9.82829 24.005 8.83337 22.7777 8.83337H7.22222C5.99492 8.83337 5 9.82829 5 11.0556V22.1667C5 23.394 5.99492 24.3889 7.22222 24.3889Z" stroke="#407BFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg><a href='#'>Info@skyoindia.com</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className='bottom-footer'>
            <div className='container col2'>
                <div className='col6'>
                    <div className='copyright'>© 2024 Skaeo All Rights Reserved</div>
                </div>
                <div className='col6'>
                    <div className='FollowUson'>
                        <h4>Follow Us on </h4>
                        <ul>
                            <li><a href=''><svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26.2497 11.4604H29.3179L22.6165 19.118L30.5 29.5395H24.3288L19.4917 23.2206L13.9637 29.5395H10.8911L18.0576 21.3475L10.5 11.4604H16.8277L21.1953 17.2362L26.2497 11.4604ZM25.1719 27.7056H26.8711L15.902 13.1988H14.0767L25.1719 27.7056Z" fill="white"/></svg></a></li>
                            <li><a href=''><svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.4768 30.9998H11.3304V17.6471H15.4768V30.9998ZM13.4013 15.8257C12.0754 15.8257 11 14.7275 11 13.4016C11 12.7647 11.253 12.1539 11.7033 11.7036C12.1537 11.2532 12.7645 11.0002 13.4013 11.0002C14.0382 11.0002 14.649 11.2532 15.0993 11.7036C15.5497 12.1539 15.8027 12.7647 15.8027 13.4016C15.8027 14.7275 14.7268 15.8257 13.4013 15.8257ZM30.9955 30.9998H26.858V24.4998C26.858 22.9507 26.8268 20.9641 24.7022 20.9641C22.5464 20.9641 22.2161 22.6471 22.2161 24.3882V30.9998H18.0741V17.6471H22.0509V19.4685H22.1089C22.6625 18.4194 24.0147 17.3123 26.0321 17.3123C30.2286 17.3123 31 20.0757 31 23.665V30.9998H30.9955Z" fill="white"/></svg></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>

    </>
  )
}

export default Footer