import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './blogs.css';

const BlogDetails = () => {
  const { id } = useParams(); // Get the blog id from the URL
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    // Fetching blog data from JSON file and finding the specific blog by id
    fetch('/blogs.json')
      .then((response) => response.json())
      .then((data) => {
        const selectedBlog = data.find((blog) => blog.id === parseInt(id));
        setBlog(selectedBlog);
      })
      .catch((error) => console.error('Error fetching blog details:', error));
  }, [id]);

  if (!blog) {
    return <p>Loading blog...</p>;
  }

  return (
    <>
      <div className='blog-detail-header'>
        <div className='blog-detail-header-inner'>
          <div className='categorytag'>{blog.category}</div>
          <h1 className='blog-title-details '>{blog.title}</h1>
          <div className='date-and-author'>
            <div className='blog-date'>{new Date(blog.postDate).toLocaleDateString()}</div>
            <span></span>
            <div className='blog-author'>{blog.author}</div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='blog-details-image'>
          <img className='' src={blog.image} alt={blog.title}/>
        </div>
        <div className='blog-details-content'>
          {/* {blog.summary} */}

          <p>In today’s educational landscape, efficiently managing student attendance is crucial for optimising both administrative processes and educational outcomes. A student attendance management system offers a sophisticated solution to traditional attendance tracking challenges. This blog explores how these systems enhance efficiency and highlights key benefits. </p>
          <h3>Simplify Daily Operations, Amplify Impact</h3>
          <p>Traditional methods of managing student attendance often involve manual roll calls and spreadsheets. These approaches, while familiar, have significant limitations: </p>
          <ul>
            <li><strong>Time-Consuming:</strong> Manual processes take up valuable instructional time. </li>
            <li><strong>Error-Prone:</strong> Manual entry increases the risk of inaccuracies in attendance records. </li>
          </ul>
          <h3>Benefits of Modern Student Attendance Management System</h3>

          <h4>1. Enhanced Accuracy and Efficiency </h4>
          <p>A modern student attendance management system enhances both accuracy and efficiency. Automated systems leverage advanced technologies such as AI and face recognition to verify attendance, significantly reducing human error and ensuring precise records. By automating routine tasks, educators save valuable time that can be redirected towards teaching and engaging with students. This not only improves the reliability of data but also streamlines workflows, making administrative processes more efficient. </p>
          <h4>2. Comprehensive Attendance Analytics </h4>
          <p>Customisable reports allow institutions to track and analyse attendance data in a way that aligns with their specific needs. This capability helps monitor student engagement and performance more effectively, enabling targeted interventions and strategic planning. </p>
          <h4>3. Customisation  </h4>
          <p>Customisation is a crucial benefit of modern attendance systems. Institutions can integrate features tailored to their unique needs, such as custom API integrations with existing School Management Systems (SMS).  </p>
          <p>Additionally, the ability to customise analytics and interfaces empowers educators to create reports and dashboards that best serve their specific requirements. This flexibility ensures that the system adapts to both current and evolving needs without disrupting existing workflows. </p>
          <h4>Improved Experience for Teachers and Students </h4>
          <p>A modern student attendance system enhances the overall experience for both educators and students. Educators benefit from streamlined attendance logging and analysis, which simplifies class management and administrative tasks. Meanwhile, students stay informed about their academic standings. These improvements contribute to a more effective and supportive educational environment.  </p>
          <h3>MyRollCallPro: Your Modern Education Solution </h3>
          <p>MyRollCallPro embodies these benefits, offering an advanced <strong>student attendance management system</strong> designed to enhance accuracy, efficiency, and user experience. With features such as AI-driven presence verification, comprehensive attendance dashboards, and customisable integrations, MyRollCallPro streamlines the attendance process, allowing educators to focus on what matters most—teaching. </p>
          <div className='Conclusion'>
            <h3>Conclusion</h3>
            <p>Adopting education solutions like MyRollCallPro brings substantial improvements to attendance management. By leveraging automated verification, comprehensive analytics, and customisable features, educational institutions can enhance their operational efficiency and better support their students and teachers. Embracing these advancements ensures that attendance management is streamlined, accurate, and conducive to a more effective educational environment. </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;