import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google'; // Import GoogleOAuthProvider
import './App.css';

// Pages
import Home from './pages/home/home';
import BlogsList from './pages/blog/blogsList';
import BlogDetails from './pages/blog/blogDetails';
import BookADemo from './pages/bookademo/bookADemo';
import GetApp from './pages/getapp/getApp';
import Pricing from './pages/pricing/pricing';
import PrivacyPolicy from './pages/privacypolicy/privacypolicy';

// Components
import Header from './components/header';
import Footer from './components/footer/footer';
import ScrollToTop from './ScrollToTop';

const AppContent = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== '/profile' && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/bloglist" element={<BlogsList />} />
        <Route path="/blog/:id" element={<BlogDetails />} />
        <Route path="/bookademo" element={<BookADemo />} />
        <Route path="/getapp" element={<GetApp />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </>
  );
};

const App = () => (
  <GoogleOAuthProvider clientId="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 ">
    <Router>
      <ScrollToTop />
      <AppContent />
    </Router>
  </GoogleOAuthProvider>
);

export default App;



