import React, { useState } from 'react';
import graphic1 from '../../assets/images/tab-icon/pin-based-graphic.svg';
import graphic2 from '../../assets/images/tab-icon/presence-graphic.svg';
import graphic3 from '../../assets/images/tab-icon/dashboards-graphic.svg';
import graphic4 from '../../assets/images/tab-icon/teacher-student-graphic.svg';
import graphic5 from '../../assets/images/tab-icon/api-intigration-graphic.svg';
import graphic6 from '../../assets/images/tab-icon/smooth-onboarding-graphic.svg';


import './style.css';


const Features = () => {
  const [activeTab, setActiveTab] = useState('tab-1');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <section className='section features-section'>
      <div className='container'>
        <h2 className='section_heading'><span className='text-gradient'>MyRollCall.Pro</span> Features</h2>
        <div className="tabs">
          <ul className="tabs-nav">
            <li className={activeTab === 'tab-1' ? 'tab-active' : ''}>
              <a href="javascrip:void()" onClick={() => handleTabClick('tab-1')}>
              <span className='tabIcon img-1'></span>
              Pin-Based<br/>
              Attendance Recording
              </a>
            </li>
            <li className={activeTab === 'tab-2' ? 'tab-active' : ''}>
              <a href="javascrip:void()" onClick={() => handleTabClick('tab-2')}>
              <span className='tabIcon img-2'></span>
              Presence<br/>
              Verification Alerts
              </a>
            </li>
            <li className={activeTab === 'tab-3' ? 'tab-active' : ''}>
              <a href="javascrip:void()" onClick={() => handleTabClick('tab-3')}>
              <span className='tabIcon img-3'></span>
              Comprehensive<br/>
              Dashboards 
              </a>
            </li>
            <li className={activeTab === 'tab-4' ? 'tab-active' : ''}>
              <a href="javascrip:void()" onClick={() => handleTabClick('tab-4')}>
              <span className='tabIcon img-4'></span>
              Teacher & Student<br/> 
              Apps 
              </a>
            </li>
            <li className={activeTab === 'tab-5' ? 'tab-active' : ''}>
              <a href="javascrip:void()" onClick={() => handleTabClick('tab-5')}>
              <span className='tabIcon img-5'></span>
              Custom Open-APIs<br/>
              for Integration
              </a>
            </li>
            <li className={activeTab === 'tab-6' ? 'tab-active' : ''}>
              <a href="javascrip:void()" onClick={() => handleTabClick('tab-6')}>
              <span className='tabIcon img-6'></span>
              Smooth<br/>
              Onboarding Process 
              </a>
            </li>
          </ul>
          <div className="tabs-stage">
              <div id="tab-1" className={activeTab === 'tab-1' ? 'tab-wrap active' : 'tab-wrap'}>
                <div className='tab-content-wrap'>
                  <div className='tab-graphic'>
                    <img src={graphic1} />
                  </div>
                  <div className='tab-content'>
                    <h3>Pin-Based Attendance Recording</h3>
                    <p>Our innovative pin-based system transforms attendance tracking into a swift, efficient process, taking less than 30 seconds to log attendance for any class size. This ensures educators can focus more on teaching and student interaction.</p>
                    <ul>
                      <li><span>Saves Time :</span> Eliminates manual, time-consuming roll calls, freeing up valuable instructional time. </li>
                      <li><span>Reduces Errors :</span> Safeguards against manual entry mistakes, reducing the prospect of discrepancies </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div id="tab-2" className={activeTab === 'tab-2' ? 'tab-wrap active' : 'tab-wrap'}>
                <div className='tab-content-wrap'>
                  <div className='tab-graphic'>
                    <img src={graphic2} />
                  </div>
                  <div className='tab-content'>
                    <h3>Presence Verification Alerts </h3>
                    <p>With AI-driven analysis and geo-location technology, MyRollCallPro ensures the accuracy of attendance records. Any anomalies trigger real-time alerts, enabling prompt intervention from faculty or administrators.</p>
                    <ul>
                      <li><span>Face Recognition Attendance System :</span> Automated attendance system using face recognition to verify each student’s identity, minimising manual intervention.</li>
                      <li><span>Geo-Verification :</span> Confirms student presence using geo-data, ensuring accountability and reliability in attendance tracking. </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div id="tab-3" className={activeTab === 'tab-3' ? 'tab-wrap active' : 'tab-wrap'}>
                <div className='tab-content-wrap'>
                  <div className='tab-graphic'>
                    <img src={graphic3} />
                  </div>
                  <div className='tab-content'>
                    <h3>Attendance Dashboards</h3>
                    <p>Our dashboards offer real-time, comprehensive views of attendance data, providing administrators with actionable insights to improve student engagement and overall performance. </p>
                    <ul>
                      <li><span>Customisable Reports :</span> Track attendance trends using filters and generate tailored reports for targeted insights.</li>
                      <li><span>Data-Driven Decisions :</span> Visual analytics help identify patterns and trends, supporting informed decisions.</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div id="tab-4" className={activeTab === 'tab-4' ? 'tab-wrap active' : 'tab-wrap'}>
                <div className='tab-content-wrap'>
                  <div className='tab-graphic'>
                    <img src={graphic4} />
                  </div>
                  <div className='tab-content'>
                    <h3>Teacher & Student Attendance Apps </h3>
                    <p>While MyRollCallPro is primarily designed to meet the needs of educators, we also provide an integrated experience for students, ensuring holistic education solutions.</p>
                    <ul>
                      <li><span>Teacher App :</span> Provides an efficient way to log and analyse attendance with advanced filtering options, streamlining class management and teaching plan.</li>
                      <li><span>Student App :</span> Sends proactive alerts to students when attendance trends decline, enabling them to stay informed and maintain their academic standing.</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div id="tab-5" className={activeTab === 'tab-5' ? 'tab-wrap active' : 'tab-wrap'}>
                <div className='tab-content-wrap'>
                  <div className='tab-graphic'>
                    <img src={graphic5} />
                  </div>
                  <div className='tab-content'>
                    <h3>Custom API Integration</h3>
                    <p>Our custom open-APIs are designed for seamless integration with your current School Management System, providing flexibility to meet your institution’s evolving requirements.</p>
                    <ul>
                      <li><span>Tailored for Growth :</span> MyRollCallPro integrates easily with existing systems and scales as your institution expands, ensuring that both current and future needs are met.</li>
                      <li><span>Seamless Compatibility :</span> Built to work in harmony with your institution’s processes, our APIs allow you to customize features as needed without any disruption to your workflows.</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div id="tab-6" className={activeTab === 'tab-6' ? 'tab-wrap active' : 'tab-wrap'}>
                <div className='tab-content-wrap'>
                  <div className='tab-graphic'>
                    <img src={graphic6} />
                  </div>
                  <div className='tab-content'>
                    <h3>Smooth Onboarding Process</h3>
                    <p>With a focus on simplicity, MyRollCallPro’s onboarding process ensures that institutions can quickly transition to our platform with minimal effort.</p>
                    <ul>
                      <li><span>User-Friendly Setup :</span> Our intuitive onboarding panel simplifies the transition, enabling administrators to get the system up and running efficiently.</li>
                      <li><span>Ongoing Support :</span> We provide dedicated support to ensure a smooth transition and long-term success.</li>
                    </ul>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>     
    </section>
    
  );
};

export default Features;
