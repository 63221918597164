import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import './loginPanle.css';
import illustration from '../../assets/images/ArrangingFile.svg';
import suceesThanks from '../../assets/images/successes.png';
import arrow from '../../assets/images/shapee.png';
import cornerShape from '../../assets/images/corner-up.png';
import GoogleLogin from './googleLogin';
import otpImage from '../../assets/images/otpe.png'; // Adjust the path as needed

export const LoginPanel = () => {
    const navigate = useNavigate(); // Initialize navigate hook

    const [selectedOption, setSelectedOption] = useState('Yes');
    const [showSecondStep, setShowSecondStep] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [emailSelected, setEmailSelected] = useState(false);
    const [modalHeight, setModalHeight] = useState('400px');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [error, setError] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showOtpModal, setShowOtpModal] = useState(false); // New state to handle OTP modal
    const [googleLoading, setGoogleLoading] = useState(false);

    const handleEmail = (email) => {
        setEmail(email);
    };

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        setShowSecondStep(true);
        setAssociateArr((prevArr) =>
            prevArr.map(item => ({
                ...item,
                checked: item.label === option
            }))
        );
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setShowOtpModal(false); // Close OTP modal if open
    };

    const handleEmailToggle = () => {
        setEmailSelected(!emailSelected);
        // Adjust modal height based on email selection
        setModalHeight(!emailSelected ? '480px' : '400px'); // Adjust heights as needed
    };

    const [associateArr, setAssociateArr] = useState([
        {
            id: 1,
            name: "associate",
            value: "yes",
            label: "Yes",
            icon: `✔`,
            checked: false,
        },
        {
            id: 2,
            name: "associate",
            value: "no",
            label: "No",
            icon: "✖",
            checked: false,
        },
    ]);

    useEffect(() => {
        if (error) {
            setModalHeight('540px'); // Increase height to accommodate error message
        } else if (showSecondStep && emailSelected) {
            setModalHeight('560px'); // Slightly increase height when email option is selected
        } else if (showSecondStep) {
            setModalHeight('460px'); // Default height for the second step
        }
    }, [emailSelected, showSecondStep, error]);

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleMobileChange = (e) => {
        const input = e.target.value;
        // Only allow numbers and ensure length does not exceed 10
        if (/^\d{0,10}$/.test(input)) {
            setMobile(input);
            // Clear error if mobile number becomes valid
            if (input.length === 10) {
                setError('');
            }
        }
    };

    const selectAssociateArr = useMemo(() => {
        return associateArr.find(item => item.checked);
    }, [associateArr]);

    const handleSubmit = async () => {
        setError('');

        // Validate email if the user provides it
        if (emailSelected && email && !isValidEmail(email)) {
            setError('Please enter a valid email address');
            return;
        }

        // Validate mobile number length on submit
        if (mobile && mobile.length !== 10) {
            setError('Please enter a valid 10-digit mobile number');
            return;
        }

        const apiUrl = 'http://66.135.18.152:8080';
        const apiKey = 'w1sxc-d35fr-tg5bn-ji2k1';

        const formData = new FormData();
        if (emailSelected) {
            formData.append('email', email);
        }

        try {
            const response = await fetch(`${apiUrl}/subscribe`, {
                method: 'POST',
                headers: {
                    'x-api-key': apiKey,
                },
                body: formData,
            });

            if (response.ok) {
                setShowSuccessModal(true);
            } else {
                const errorResult = await response.text();
                setError('Failed to subscribe. Please try again.');
            }
        } catch (error) {
            setError('Error submitting data. Please try again.');
        }
    };

    const handleExploreMoreClick = () => {
        // Show the OTP modal when the user clicks on Explore More
        setShowSuccessModal(false);
        setShowOtpModal(true);
    };

    const handleOtpSubmit = () => {
        // When user clicks Continue after OTP
        navigate('/profile'); // Use navigate to redirect to Profile Page
    };

    return (
        <>
            {isModalOpen && (
                <div className="modal-overlay">
                    {showOtpModal ? (
                        <div className="otp-modal-container">
                            <div className="otp-modal-content">
                                <button className="close-button" onClick={handleCloseModal}>✖</button>
                                <img src={otpImage} alt="OTP Verification" className="otp-image" />
                                <h2>We sent you a code to verify your Email</h2>
                                <div className="otp-input-container">
                                    <input type="text" maxLength="1" />
                                    <input type="text" maxLength="1" />
                                    <input type="text" maxLength="1" />
                                    <input type="text" maxLength="1" />
                                    <input type="text" maxLength="1" />
                                    <input type="text" maxLength="1" />
                                </div>
                                <p>
                                    Didn't receive? <a href="#">Resend in 00:05</a>
                                </p>
                                <button className="continue-button" onClick={handleOtpSubmit}>
                                    Continue →
                                </button>
                            </div>
                        </div>
                    ) : showSuccessModal ? (
                        <div className="success-modal-card">
                            <button className="close-button" onClick={handleCloseModal}>✖</button>
                            <img src={suceesThanks} alt="Success Illustration" className="success-image" />
                            <h2>Thank You for Your Interest in <span className="highlighted-text">MyRollCall!</span></h2>
                            <p className='p-thanks'>We will keep you updated with our<br /> upcoming features.</p>
                            <button className="explore-button" onClick={handleExploreMoreClick}>
                                Explore More →
                            </button>
                        </div>
                    ) : (
                        <div className={`modal-container ${error ? 'modal-container-error' : ''}`} style={{ height: modalHeight }}>
                            <img src={cornerShape} className="corner-shape" alt="Corner Shape" />
                            <button className="close-button" onClick={handleCloseModal}>✖</button>

                            {!showSecondStep ? (
                                <div className="modal-content">
                                    <div className="modal-left">
                                        <h2 className="modal-title">Welcome! Let's Get Started</h2>
                                        <p className="modal-subtitle">Please enter your details to begin with.</p>
                                        <div className="question-box">
                                            <p className="question-text">Are you associated with any institute?</p>
                                            <div className="button-group">
                                                <button
                                                    className={`option-button ${selectedOption === 'Yes' ? 'selected' : ''}`}
                                                    onClick={() => handleOptionSelect('Yes')}
                                                >
                                                    ✔ YES
                                                </button>
                                                <button
                                                    className={`option-button ${selectedOption === 'No' ? 'selected' : ''}`}
                                                    onClick={() => handleOptionSelect('No')}
                                                >
                                                    ✖ NO
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-right">
                                        <img src={illustration} className="illustration-image" alt="Illustration" />
                                    </div>
                                </div>
                            ) : (
                                <div className="modal-content-second">
                                    <div className="modal-left-second">
                                        <h2 className="modal-title">Welcome! Let's Get Started</h2>
                                        <p className="modal-subtitle">Please enter your details to begin with.</p>
                                        <div className="login-modal__question">
                                            <div className='selectAssociateArr'>
                                                Are you associated with any institute?
                                                <span>
                                                    {selectAssociateArr ? (
                                                        <>
                                                            <i>{selectAssociateArr.icon}</i>
                                                            <label>{selectAssociateArr.label}</label>
                                                        </>
                                                    ) : (
                                                        <label>No selection made</label>
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <GoogleLogin handleEmail={handleEmail} googleLoading={googleLoading} />
                                        <div className="login-modal__divider-second">
                                            <span className="login-modal__divider-text-second">OR</span>
                                        </div>

                                        <div className="login-modal__email-option-second">
                                            <input
                                                type="checkbox"
                                                className="login-modal__checkbox"
                                                id="email"
                                                onChange={handleEmailToggle}
                                            />
                                            <label htmlFor="email" className="login-modal__email-text">Continue with Email</label>
                                        </div>
                                        {emailSelected && (
                                            <div className="login-modal__email-fields-second">
                                                <input
                                                    type="email"
                                                    className="login-modal__email-input-second"
                                                    placeholder="Email"
                                                    value={email}
                                                    onChange={(e) => {
                                                        setEmail(e.target.value);
                                                        if (error) {
                                                            setError(''); // Clear error when user starts typing
                                                        }
                                                    }}
                                                />
                                                <input
                                                    type="tel"
                                                    className="login-modal__mobile-input-second"
                                                    placeholder="Mobile (Optional)"
                                                    value={mobile}
                                                    onChange={handleMobileChange}
                                                />
                                            </div>
                                        )}

                                        {error && <p className="error-message">{error}</p>}
                                        <p className="login-modal__terms">
                                            By accessing or using our website you agree to comply with and be bound by these <a href="#">Terms</a> and <a href="#">Conditions</a>.
                                        </p>

                                        <button
                                            className="login-modal__continue-button-second"
                                            onClick={() => {
                                                if (selectedOption === 'Yes') {
                                                    handleSubmit();
                                                } else if (selectedOption === 'No' && emailSelected) {
                                                    if (email && isValidEmail(email)) {
                                                        handleSubmit();
                                                    } else if (!email) {
                                                        setError('Email is required');
                                                    } else {
                                                        setError('Please enter a valid email address');
                                                    }
                                                } else {
                                                    setError('Please provide an email address to continue.');
                                                }
                                            }}
                                        >
                                            Continue <img src={arrow} className="arrow" alt="arrow" />
                                        </button>
                                    </div>
                                    <div className="modal-right-second">
                                        <img src={illustration} className="illustration-image-second" alt="Illustration" />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
