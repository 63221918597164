import React from 'react'
import leftGraphic from '../../assets/images/your-smart-attendance-app.svg';
import lightShap from '../../assets/images/light-shap.svg';
import rightLineShap from '../../assets/images/right-line-shap.svg';

import './style.css';

function AboutSection() {
  return (
    <section id='about' className='section'>
      <span className='lightShap'><img src={lightShap}/></span>
      <div className='container'>
        <div className='about-section'>
          <div className='left_image'>
            <img src={leftGraphic}/>
          </div>
          <div className='about_content'>
            <h2 className='section_heading'>Your Smart Attendance App</h2>
            <p>MyRollCall.Pro transforms attendance monitoring system with a <u>sophisticated, technology-driven approach. </u></p>
            <p>Traditional class attendance is cumbersome and prone to inaccuracies. These inefficiencies disrupt the educational experience and detract from the primary focus on student learning. </p>
            <p>MyRollCallPro addresses the issues with our pin-based approach that simplifies and accelerates the student attendance management system, ensuring <u>precision and reliability. </u></p>
          </div>
        </div>
      </div>
      <span className='rightLineShap'><img src={rightLineShap}/></span>
    </section>
  )
}

export default AboutSection