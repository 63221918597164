/* global gtag */
import { Button, Form, Modal } from "react-bootstrap";
import { useState } from "react";
import RequestCall from "../../assets/images/calculate-price-graphic.svg";
import calculatingAnimation from "../../assets/images/price-calculating-animation.gif";
import "./style.css";
import { useNavigate } from "react-router-dom";

function CalculatePriceCTA() {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({ institution: "", students: "", email: "", phone: "" });
    const [errors, setErrors] = useState({});
    const [isCalculating, setIsCalculating] = useState(false);
    const [isCalculated, setIsCalculated] = useState(false);

    const handleClose = () => {
        setShow(false);
        setIsCalculating(false);
        setIsCalculated(false);
        setErrors({});
    };
    const handleShow = () => setShow(true);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        let newErrors = {};
        if (!formData.institution) newErrors.institution = "Institution Name is required";
        if (!formData.students) newErrors.students = "Number of Students is required";
        if (!formData.email) newErrors.email = "Email is required";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        gtag('event', 'custom_pricing_submit');

        setIsCalculating(true);
        setTimeout(() => {
            setIsCalculating(false);
            setIsCalculated(true);
        }, 3000);
    };

    const handleButtonClick = () => {
        // Send custom event to gtag
            gtag('event', 'custom_pricing_click');
        handleShow();
    };

    return (
        <>
            <div className="CalculatePriceCTA">
                <div className="container">
                    <div className="cta_banner_wrapper">
                        <div className="cta_banner_content">
                            <h2>Get Custom Pricing Instantly</h2>
                            <p>No hidden fees. Only the price that fits your needs.</p>
                        </div>
                        <button className="call_back_button" onClick={handleButtonClick}>
                            Calculate Now
                        </button>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="Second_cta_forms_modal">
                <Modal.Body>
                    <div className="graphic_side">
                        <img src={RequestCall} alt="Request Call" />
                    </div>

                    <div className="form_side">
                        <Modal.Header closeButton>
                            <Modal.Title>Get Custom Pricing Details</Modal.Title>
                        </Modal.Header>
                        <Form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-12">
                                    <Form.Group className="mb-3">
                                        <Form.Control type="text" placeholder="Institution Name" name="institution" value={formData.institution} onChange={handleChange} />
                                        {errors.institution && <small className="text-danger">{errors.institution}</small>}
                                    </Form.Group>
                                </div>
                                <div className="col-md-12">
                                    <Form.Group className="mb-3">
                                        <Form.Control type="text" placeholder="Number of Students" name="students" value={formData.students} onChange={handleChange} />
                                        {errors.students && <small className="text-danger">{errors.students}</small>}
                                    </Form.Group>
                                </div>
                                <div className="col-md-12">
                                    <Form.Group className="mb-3">
                                        <Form.Control type="email" placeholder="Email" name="email" value={formData.email} onChange={handleChange} />
                                        {errors.email && <small className="text-danger">{errors.email}</small>}
                                    </Form.Group>
                                </div>
                                <div className="col-md-12">
                                    <Form.Group className="mb-4">
                                        <Form.Control type="text" placeholder="Phone (Optional)" name="phone" value={formData.phone} onChange={handleChange} />
                                    </Form.Group>
                                </div>
                            </div>
                            <Button variant="primary" type="submit" className="w-100">
                                Submit
                            </Button>
                        </Form>

                        {isCalculating && (
                            <div className="calculating_price_loader">
                                <img src={calculatingAnimation} alt="Calculating" />
                                <h4>Calculating price...</h4>
                            </div>
                        )}

                        {isCalculated && (
                            <div className="calculated_price_details">
                                <h4>Pricing Details</h4>
                                <p>We have calculated the price based on the details you provided. Please note that this is an approximate estimate.</p>
                                <div className="Price_details_wrapper">
                                    <ul>
                                        <li>Institute name <span>{formData.institution}</span></li>
                                        <li>Total Students <span>{formData.students}</span></li>
                                        <li>Price Per student <span>₹40</span></li>
                                        <li className="TotalPrice">Total Price <span id="total_price">₹{formData.students * 40}</span></li>
                                    </ul>
                                </div>
                                <Button variant="primary" className="w-100" onClick={()=>navigate("/demo_request")}>Contact Now</Button>
                            </div>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CalculatePriceCTA;
