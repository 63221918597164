import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LoginPanel } from './loginPanel'; // Named export - add curly braces
// Default export import - no curly braces
import freeimg from '../../assets/images/free-img.png';
import './price.css';

function Pricing() {
  const [panelVisible, setPanelVisible] = useState(false);

  const togglePanel = (event) => {
    event.stopPropagation(); // Prevent event bubbling
    setPanelVisible((prev) => !prev); // Toggle the visibility state
  };

  return (
    <>
      <div className='innerpage-headerbanner'>
        <h1>Pricing</h1>
      </div>

      <div className='price-page-wrap'>
        <h3 className='price-heading'>
          Affordable Attendance Solutions for Your <strong>Institution</strong>
        </h3>

        <div className='price-page-card'>
          <div className='price-card-header'>
            <h3><span>₹19</span> Per student/month</h3>
            <button className='EnrollNow'>Enroll Now</button>
            <h4>
              Want to see it in action first? <Link to='/bookademo'>Schedule a free demo.</Link>
            </h4>
            <div className='freecard'>
              <img src={freeimg} alt="Free Offer" />
              <div className='free-content'>
                <h2>Early Bird Offer - <strong>FREE</strong></h2>
                <p>Unlock access for the first <strong>50 students</strong> at <strong>Zero</strong> cost.</p>
              </div>
            </div>
          </div>

          {/* Render the LoginPanel component within the students-card */}
          <div className='students-card' onClick={togglePanel}>
            <h5>Have more than 50 students? </h5>
            <p>Contact us for discounted pricing tailored to your institution’s needs.</p>
            <div className='students-buttons'>
              <Link to='/bookademo' className='btn RequestaDemo'>Request a Demo</Link>
              <button className='btn GetStarted'>Get Started</button>
            </div>
          </div>
        </div>

        {panelVisible && <LoginPanel className='custom-login-panel' />}

        <div className='EnhanceYourExperience'>
          <div className='headings text-center'>
            <h2>Enhance Your Experience</h2>
            <p>Unlock additional capabilities with our optional add-ons, <span>available after enrollment.</span></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pricing;