
import React from 'react'
import Banner from '../../components/banner';
import Accordion from '../../components/faq';
import Features from '../../components/features'
import TrunStudent from '../../components/turnstudent';
import AboutSection from '../../components/yoursmartattendance';
import CallBackCTA from '../../components/callBackCTA';
import GetaFreeReport from '../../components/GetaFreeReport';


function Home({ playVideo }) {
  return (
    <>

    <Banner playVideo={playVideo} />
    <CallBackCTA/>
    <AboutSection/>
    <TrunStudent/>
    <GetaFreeReport/>
    <Features/>
    <Accordion/>    

    </>
  );
}

export default Home;
