/* global gtag */
import { Button, Form, Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import RequestCall from "../../assets/images/Request_a_call.svg";
import "./style.css";

function CallBackCTA() {
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false); // Track submission status


    // Open modal after 5 seconds on page load
    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(true);
        }, 3000); // 5 seconds delay

        return () => clearTimeout(timer); // Cleanup if component unmounts
    }, []);

    const handleClose = () => {
        setShow(false);
        setName("");
        setPhone("");
        setErrors({});
        setSubmitted(false); // Reset submission status when modal closes
    };

    const handleShow = () => setShow(true);

    const validateForm = () => {
        let newErrors = {};
        
        if (!name.trim()) {
            newErrors.name = "Full Name is required";
        }
        if (!phone.trim()) {
            newErrors.phone = "Phone Number is required";
        } else if (!/^\d{10}$/.test(phone)) {
            newErrors.phone = "Enter a valid 10-digit phone number";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Returns true if no errors
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setSubmitted(true); // Show after_submit_msg
        }
        gtag('event', 'callback_submit');
    };

    const handleButtonClick = () => {
        // Send custom event to gtag
            gtag('event', 'callback_click');
        handleShow();
    }

    return (
        <>
            <div className="Call_back_cta_banner">
                <div className="container">
                    <div className="cta_banner_wrapper">
                        <div className="cta_banner_content">
                            <h2>Need Specific Answers?</h2>
                            <p>No hassle, No Spam</p>
                        </div>
                        <button className="call_back_button" onClick={handleButtonClick}>
                            Request A Call Back
                        </button>
                    </div>
                </div>
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="cta_forms_modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Request a Callback</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form_side">
                        {!submitted ? (
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="nameInput">
                                    <Form.Control
                                        type="text"
                                        placeholder="Full Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-4" controlId="phoneInput">
                                    <Form.Control
                                        type="text"
                                        maxLength={10}
                                        placeholder="Phone Number"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        isInvalid={!!errors.phone}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.phone}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        ) : (
                            <div className="after_submit_msg">
                                <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M54.77 34.2702C55.7708 33.2696 55.7708 31.6471 54.77 30.6464C53.7693 29.6457 52.147 29.6457 51.1463 30.6464L35.8748 45.9176L30.8535 40.8965C29.8527 39.8958 28.2302 39.8958 27.2295 40.8965C26.2288 41.8972 26.2288 43.5195 27.2295 44.5202L34.0629 51.3536C35.0637 52.3543 36.6859 52.3543 37.6867 51.3536L54.77 34.2702Z" fill="#00CA78"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M41.0002 4.27081C20.7152 4.27081 4.271 20.715 4.271 41C4.271 61.2851 20.7152 77.7291 41.0002 77.7291C61.2853 77.7291 77.7293 61.2851 77.7293 41C77.7293 20.715 61.2853 4.27081 41.0002 4.27081ZM9.396 41C9.396 23.5455 23.5457 9.39581 41.0002 9.39581C58.4545 9.39581 72.6043 23.5455 72.6043 41C72.6043 58.4544 58.4545 72.6041 41.0002 72.6041C23.5457 72.6041 9.396 58.4544 9.396 41Z" fill="#00CA78"/>
                                </svg>
                                <h3>Thank you! </h3>
                                <p>
                                    Your callback request has been received.
                                    Our expert will reach out to you shortly.
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="graphic_side">
                        <img src={RequestCall} alt="Request a Call" />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CallBackCTA;
