import React from 'react'
import Banner from '../../components/banner';
import Accordion from '../../components/faq';
import Features from '../../components/features'
import TrunStudent from '../../components/turnstudent';
import AboutSection from '../../components/yoursmartattendance';

function Home() {
  return (
    <>
    <Banner/>
    <AboutSection/>
    <TrunStudent/>
    <Features/>
    <Accordion/>
    
    </>
  )
}

export default Home