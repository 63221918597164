import React from 'react'
import screen1 from '../../assets/images/screen-1.png';
import screen2 from '../../assets/images/screen-2.png';

import './style.css';

function GetApp() {
  return (
    <>
      <div className='innerpage-headerbanner'>
          <h1>Get App</h1>
      </div>
      
      <div className='downloadApp'>
        <h2>Download the <span>Student Attendance</span> App. </h2>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.</p>
        <div className='download-store-buttons'>
          <button><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.5492 5.05083C21.1478 4.36094 21.6042 3.5596 21.8922 2.69283C22.1802 1.82606 22.2942 0.910936 22.2275 0C20.3899 0.148338 18.684 1.01263 17.4775 2.40667C16.8998 3.07479 16.462 3.85206 16.19 4.69244C15.918 5.53281 15.8174 6.41922 15.8942 7.29917C16.7906 7.30664 17.6767 7.10773 18.4839 6.71786C19.2911 6.32798 19.9978 5.75759 20.5492 5.05083ZM24.5392 16.8308C24.5498 15.6176 24.8694 14.427 25.4678 13.3716C26.0662 12.3161 26.9236 11.4305 27.9592 10.7983C27.3056 9.85663 26.4417 9.08015 25.4358 8.53049C24.4299 7.98082 23.3097 7.67308 22.1642 7.63167C19.6942 7.37833 17.4142 9.0725 16.1 9.0725C14.7859 9.0725 12.9334 7.66333 10.875 7.695C9.52941 7.73933 8.21824 8.13165 7.06942 8.83371C5.92059 9.53577 4.97334 10.5236 4.32004 11.7008C1.53337 16.5458 3.60754 23.75 6.39421 27.6608C7.66087 29.5767 9.24421 31.7458 11.3342 31.6667C13.4242 31.5875 14.105 30.3683 16.5275 30.3683C18.95 30.3683 19.6942 31.6667 21.7525 31.6192C23.8109 31.5717 25.2675 29.6558 26.5975 27.74C27.5397 26.3491 28.2757 24.8292 28.7825 23.2275C27.5277 22.6924 26.4571 21.8014 25.703 20.6646C24.9489 19.5278 24.5443 18.195 24.5392 16.8308Z" fill="white"/>
          </svg> App Store</button>
          <button><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.1772 15.6199L21.8591 10.938L6.73667 2.43864C5.73442 1.89714 4.7955 1.82114 3.97217 2.41331L17.1772 15.6199ZM22.6571 21.1014L27.5242 18.3638C28.4742 17.8318 28.9952 17.0781 28.9952 16.2421C28.9952 15.4077 28.4742 14.6525 27.5258 14.1205L23.1194 11.6457L18.1588 16.6047L22.6571 21.1014ZM3.15833 3.56439C3.057 3.8763 3 4.22464 3 4.60622V27.8891C3 28.4924 3.133 29.0117 3.37367 29.425L16.1939 16.6031L3.15833 3.56439ZM17.1772 17.5848L4.42817 30.3354C4.672 30.4288 4.93483 30.4779 5.21192 30.4779C5.70592 30.4779 6.2205 30.3322 6.74458 30.0409L21.3983 21.8123L17.1772 17.5848Z" fill="white"/>
          </svg> Google Play</button>
        </div>

        <div className='appscreens'>
          <div className='screenview'><img src={screen1} /></div>
          <div className='screenview'><img src={screen2} /></div>
        </div>
      </div>
    </>
  )
}

export default GetApp