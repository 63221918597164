import React, { useEffect } from 'react';

function BookADemo() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <>
      <div className='innerpage-headerbanner'>
        <h1>Book A Demo</h1>
      </div>

      <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/rdeepak320?hide_landing_page_details=1&hide_gdpr_banner=1"
        style={{ minWidth: '320px', height: '700px' }}
      ></div>
    </>
  );
}

export default BookADemo;
