// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-section {
    display: flex;
    gap: 6vw;
    padding: 80px 0 70px 0;
}
.about-section .left_image {
    width: 100%;
    max-width: fit-content;
}
.about-section .about_content {
    width: 100%;
    max-width: 50%;
}
span.lightShap {
    position: absolute;
    bottom: 30px;
    left: 0;
}
span.rightLineShap {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 70px;
}

@media (max-width: 1500px){
    .about-section{
        gap: 2.5vw;
    }
    
}

@media (max-width: 991px){
    .about-section {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 10px;
    }
    .about-section .about_content {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 567px){
    span.rightLineShap,
    span.leftLineShap{
        max-width: 26px;
    }
    .about-section {
        padding: 30px 0 70px 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/yoursmartattendance/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,QAAQ;IACR,sBAAsB;AAC1B;AACA;IACI,WAAW;IACX,sBAAsB;AAC1B;AACA;IACI,WAAW;IACX,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,OAAO;AACX;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,MAAM;IACN,eAAe;AACnB;;AAEA;IACI;QACI,UAAU;IACd;;AAEJ;;AAEA;IACI;QACI,sBAAsB;QACtB,mBAAmB;QACnB,kBAAkB;QAClB,SAAS;IACb;IACA;QACI,WAAW;QACX,eAAe;IACnB;AACJ;;AAEA;IACI;;QAEI,eAAe;IACnB;IACA;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".about-section {\n    display: flex;\n    gap: 6vw;\n    padding: 80px 0 70px 0;\n}\n.about-section .left_image {\n    width: 100%;\n    max-width: fit-content;\n}\n.about-section .about_content {\n    width: 100%;\n    max-width: 50%;\n}\nspan.lightShap {\n    position: absolute;\n    bottom: 30px;\n    left: 0;\n}\nspan.rightLineShap {\n    position: absolute;\n    right: 0;\n    top: 0;\n    max-width: 70px;\n}\n\n@media (max-width: 1500px){\n    .about-section{\n        gap: 2.5vw;\n    }\n    \n}\n\n@media (max-width: 991px){\n    .about-section {\n        flex-direction: column;\n        align-items: center;\n        text-align: center;\n        gap: 10px;\n    }\n    .about-section .about_content {\n        width: 100%;\n        max-width: 100%;\n    }\n}\n\n@media (max-width: 567px){\n    span.rightLineShap,\n    span.leftLineShap{\n        max-width: 26px;\n    }\n    .about-section {\n        padding: 30px 0 70px 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
