import React from 'react'
import './style.css';
import discountTag from '../assets/images/discount-tag.png';

function TopStrip() {
  return (
    <div className='topStrip'>
        <img src={discountTag} alt="Discount Tag" /> Sign up and receive 30% bonus discount on checkout
    </div>
  )
}

export default TopStrip