/* global gtag */
import React, { useState } from 'react';
import { useLocation , Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import graphic1 from '../../assets/images/tab-icon/pin-based-graphic.svg';
import graphic2 from '../../assets/images/tab-icon/presence-graphic.svg';
import graphic3 from '../../assets/images/tab-icon/dashboards-graphic.svg';
import graphic4 from '../../assets/images/tab-icon/teacher-student-graphic.svg';
import graphic5 from '../../assets/images/tab-icon/api-intigration-graphic.svg';
import graphic6 from '../../assets/images/tab-icon/smooth-onboarding-graphic.svg';


import './style.css';


const Features = () => {
  const location = useLocation(); // Get the current path
  const [activeTab, setActiveTab] = useState('tab-1');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    
  };

  const trackEvent = (elementId) => {
    // Get the current page location
    const currentPath = location.pathname;

    // Format the current path to ensure correct separators
    const formattedPath = currentPath
      .replace("/", "")
      .replace(/^$/, "home_features");

    // Construct the event name dynamically
    const eventName = `${formattedPath}_${elementId}_click`;

   

 
    // Send the event data to GA4
    gtag("event", eventName);
  };

  // Trigger GA4 events when navigation links are clicked
  const handleLinkClick = (to, elementId) => {
    trackEvent(elementId);
  };

  return (
    <>
     <Helmet>
        <title>Features - MyRollCall.Pro</title>
        <meta
          name="description"
          content="Explore the innovative features of MyRollCall.Pro, designed to enhance attendance management and administrative efficiency."
        />
        <meta name="h2" content="MyRollCall.Pro Features" />
        <meta
          name="h3"
          content="Pin-Based Attendance Recording Presence Verification Alerts Attendance Dashboards Teacher & Student Attendance Apps Custom API Integration Smooth Onboarding Process"
        />
      </Helmet>
      <section className='section features-section'>
      <div className='container'>
        <h2 className='section_heading'><span className='text-gradient'>MyRollCall.Pro</span> Features</h2>
        <div className="tabs">
          <ul className="tabs-nav">
            <li className={activeTab === 'tab-1' ? 'tab-active' : ''}>
              <Link id='Pin_Based_Attendance_Recording' onClick={() => {handleTabClick('tab-1');  handleLinkClick("/pin_based", "pin_based")}} >
              <span className='tabIcon img-1'></span>
              Pin-Based<br/> Attendance Recording
              </Link>
            </li>
            <li className={activeTab === 'tab-2' ? 'tab-active' : ''}>
              <Link id='Presence_Verification_Alerts' onClick={() => {handleTabClick('tab-2');handleLinkClick("/presence_alerts", "presence_alerts") }}>
              <span className='tabIcon img-2'></span>
              Presence<br/> Verification Alerts
              </Link>
            </li>
            <li className={activeTab === 'tab-3' ? 'tab-active' : ''}>
              <Link id='Comprehensive_Dashboards' onClick={() => {handleTabClick('tab-3');handleLinkClick("/dashboards", "dashboards") }}>
              <span className='tabIcon img-3'></span>
              Comprehensive<br/> Dashboards 
              </Link>
            </li>
            <li className={activeTab === 'tab-4' ? 'tab-active' : ''}>
              <Link id='Teacher_Student_Apps' onClick={() => {handleTabClick('tab-4');handleLinkClick("/teacher_student", "teacher_student")} }>
              <span className='tabIcon img-4'></span>
              Teacher & Student<br/> Apps 
              </Link>
            </li>
            <li className={activeTab === 'tab-5' ? 'tab-active' : ''}>
              <Link id='Custom_Open_APIs_Integration' onClick={() =>{handleTabClick('tab-5');handleLinkClick("/api_integration", "api_integration")}}>
              <span className='tabIcon img-5'></span>
              Custom Open-APIs<br/> for Integration
              </Link>
            </li>
            <li className={activeTab === 'tab-6' ? 'tab-active' : ''}>
              <Link id='Smooth_Onboarding_Process' onClick={() => {handleTabClick('tab-6');handleLinkClick("/onboarding", "onboarding")}}>
              <span className='tabIcon img-6'></span>
              Smooth<br/> Onboarding Process 
              </Link>
            </li>
          </ul>
          <div className="tabs-stage">
              <div id="tab-1" className={activeTab === 'tab-1' ? 'tab-wrap active' : 'tab-wrap'}>
                <div className='tab-content-wrap'>
                  <div className='tab-graphic'>
                    <img src={graphic1} alt='' />
                  </div>
                  <div className='tab-content'>
                    <h3>Pin-Based Attendance Recording</h3>
                    <p>Our innovative pin-based system ensures that attendance takes less than 30 seconds for any class size. </p>
                    <ul>
                      <li><span>Reduces Errors :</span> Eliminate manual data entry mistakes, ensuring reliable and verifiable records. </li>
                      {/* <li><span>Comprehensive Dashboard :</span>  Strategic Insights : Visual analytics help identify patterns and trends, supporting effective decision-making. </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div id="tab-2" className={activeTab === 'tab-2' ? 'tab-wrap active' : 'tab-wrap'}>
                <div className='tab-content-wrap'>
                  <div className='tab-graphic'>
                    <img src={graphic2} alt='' />
                  </div>
                  <div className='tab-content'>
                    <h3>Presence Verification Alerts </h3>
                    <p>With AI-driven analysis and geo-location technology, MyRollCallPro ensures the accuracy of attendance records. Any anomalies trigger real-time alerts, enabling prompt intervention from faculty or administrators.</p>
                    <ul>
                      <li><span>Face Recognition Attendance System :</span> Automated attendance system using face recognition to verify each student’s identity, minimising manual intervention.</li>
                      <li><span>Geo-Verification :</span> Confirms student presence using geo-data, ensuring accountability and reliability in attendance tracking. </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div id="tab-3" className={activeTab === 'tab-3' ? 'tab-wrap active' : 'tab-wrap'}>
                <div className='tab-content-wrap'>
                  <div className='tab-graphic'>
                    <img src={graphic3} alt='' />
                  </div>
                  <div className='tab-content'>
                    <h3>Attendance Dashboards</h3>
                    <p>Our dashboards offer real-time, comprehensive views of attendance data, providing administrators with actionable insights to improve student engagement and overall performance. </p>
                    <ul>
                      <li><span>Customisable Reports :</span> Track attendance trends using filters and generate tailored reports for targeted insights.</li>
                      <li><span>Data-Driven Decisions :</span> Visual analytics help identify patterns and trends, supporting informed decisions.</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div id="tab-4" className={activeTab === 'tab-4' ? 'tab-wrap active' : 'tab-wrap'}>
                <div className='tab-content-wrap'>
                  <div className='tab-graphic'>
                    <img src={graphic4} alt='' />
                  </div>
                  <div className='tab-content'>
                    <h3>Teacher & Student Attendance Apps </h3>
                    <p>While MyRollCall.pro is primarily designed to facilitate educator solutions, we also provide an integrated experience for students, ensuring a holistic education solution.</p>
                    <ul>
                      <li><span>Teacher App :</span> Provides an efficient way to log and analyse attendance with advanced filtering options, streamlining class management and teaching plan.</li>
                      <li><span>Student App :</span> Sends proactive alerts to students when attendance trends decline, enabling them to stay informed and maintain their academic standing.</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div id="tab-5" className={activeTab === 'tab-5' ? 'tab-wrap active' : 'tab-wrap'}>
                <div className='tab-content-wrap'>
                  <div className='tab-graphic'>
                    <img src={graphic5} alt='' />
                  </div>
                  <div className='tab-content'>
                    <h3>Custom API Integration</h3>
                    <p>Our custom API is designed to integrate seamlessly with your existing school management system, allowing you to keep using your current tools while enhancing functionality.</p>
                    <ul>
                    <li><span>Seamless Compatibility :</span> Built to work in harmony with your institution’s process, our API allows you to customize features as needed with no disruption to your workflow.</li>
                      <li><span>Tailored for Growth :</span> As your institution evolves, MyRollCall.pro scales with ease, ensuring both current and future needs are met in a cost-efficient way.</li>                      
                    </ul>
                  </div>
                </div>
              </div>

              <div id="tab-6" className={activeTab === 'tab-6' ? 'tab-wrap active' : 'tab-wrap'}>
                <div className='tab-content-wrap'>
                  <div className='tab-graphic'>
                    <img src={graphic6} alt='' />
                  </div>
                  <div className='tab-content'>
                    <h3>Smooth Onboarding Process</h3>
                    <p>With a focus on simplicity, MyRollCallPro’s onboarding process ensures that institutions can quickly transition to our platform with minimal effort.</p>
                    <ul>
                      <li><span>User-Friendly Setup :</span> Our intuitive onboarding panel simplifies the transition, enabling administrators to get the system up and running efficiently.</li>
                      <li><span>Ongoing Support :</span> We provide dedicated support to ensure a smooth transition and long-term success.</li>
                    </ul>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>     
    </section>
    </>

    
  );
};

export default Features;
