import React from "react";
import MrcpVideo from "../../assets/images/mrcp-video.mp4";

const HomeVideo = () => {
  return (
    <>
      <video className="intro-video" controls>
        <source src={MrcpVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>{" "}
    </>
  );
};

export default HomeVideo;
