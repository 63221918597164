/* global gtag */
import { Button, Form, Modal } from "react-bootstrap";
import { useState } from 'react';
import RequestCall from '../../assets/images/custom-consultion.svg';
import "./style.css";

function BookaConsultation() {
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        dateTime: "",
    });
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleClose = () => {
        setShow(false);
        setIsSubmitted(false); // Reset success message
        setFormData({ name: "", email: "", phone: "", dateTime: "" }); // Reset form data
        setErrors({}); // Clear validation errors
    };
    const handleShow = () => setShow(true);

    const validateForm = () => {
        let errors = {};
        if (!formData.name.trim()) errors.name = "Name is required";
        if (!formData.email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email))
            errors.email = "Valid email is required";
        if (!formData.phone.trim() || !/^\d{10}$/.test(formData.phone))
            errors.phone = "Valid 10-digit phone number is required";
        if (!formData.dateTime.trim()) errors.dateTime = "Preferred Date & Time is required";

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsSubmitted(true); // Show success message
        }
        gtag('event', 'custom_consultation_submit');
    };
    const handleButtonClick = () => {
        // Send custom event to gtag
            gtag('event', 'custom_consultation_click');
        handleShow();
    };

    return (
        <>
            <div className='BookaConsultation'>
                <div className="container">
                    <div className="cta_banner_wrapper">
                        <div className="cta_banner_content">
                            <h2>No Time for a Demo?</h2>
                            <p>Book a quick consultation for personalised insights.</p>
                        </div>
                        <button className='call_back_button' onClick={handleButtonClick}>
                            Book a Consultation
                        </button>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} size="lg" centered className="Second_cta_forms_modal CustomConsultation">
                <Modal.Body>
                    <div className="graphic_side">
                        <img src={RequestCall} alt="Request a Call" />
                    </div>

                    <div className="form_side">
                        <Modal.Header closeButton>
                            <Modal.Title>Get a Free Custom Consultation</Modal.Title>
                        </Modal.Header>

                        {!isSubmitted ? (
                            <Form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Form.Group className="mb-3">
                                            <Form.Control
                                                type="text"
                                                placeholder="Name"
                                                value={formData.name}
                                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                            />
                                            {errors.name && <p className="error-text text-danger"><small>{errors.name}</small></p>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-12">
                                        <Form.Group className="mb-3">
                                            <Form.Control
                                                type="email"
                                                placeholder="Email"
                                                value={formData.email}
                                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                            />
                                            {errors.email && <p className="error-text text-danger"><small>{errors.email}</small></p>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-12">
                                        <Form.Group className="mb-3">
                                            <Form.Control
                                                type="text"
                                                placeholder="Phone Number"
                                                maxLength={10}
                                                value={formData.phone}
                                                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                                            />
                                            {errors.phone && <p className="error-text text-danger"><small>{errors.phone}</small></p>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-12">
                                        <Form.Group className="mb-4">
                                            <Form.Control
                                                type="text"
                                                placeholder="Preferred Date & Time for a Call"
                                                value={formData.dateTime}
                                                onChange={(e) => setFormData({ ...formData, dateTime: e.target.value })}
                                            />
                                            {errors.dateTime && <p className="error-text text-danger"><small>{errors.dateTime}</small></p>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <Button type="submit" variant="primary">
                                    Submit
                                </Button>
                            </Form>
                        ) : (
                            <div className="after_submit_msg">
                                <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M54.77 34.2702C55.7708 33.2696 55.7708 31.6471 54.77 30.6464C53.7693 29.6457 52.147 29.6457 51.1463 30.6464L35.8748 45.9176L30.8535 40.8965C29.8527 39.8958 28.2302 39.8958 27.2295 40.8965C26.2288 41.8972 26.2288 43.5195 27.2295 44.5202L34.0629 51.3536C35.0637 52.3543 36.6859 52.3543 37.6867 51.3536L54.77 34.2702Z" fill="#00CA78"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M41.0002 4.27081C20.7152 4.27081 4.271 20.715 4.271 41C4.271 61.2851 20.7152 77.7291 41.0002 77.7291C61.2853 77.7291 77.7293 61.2851 77.7293 41C77.7293 20.715 61.2853 4.27081 41.0002 4.27081ZM9.396 41C9.396 23.5455 23.5457 9.39581 41.0002 9.39581C58.4545 9.39581 72.6043 23.5455 72.6043 41C72.6043 58.4544 58.4545 72.6041 41.0002 72.6041C23.5457 72.6041 9.396 58.4544 9.396 41Z" fill="#00CA78"/>
                                </svg>
                                <h3>Thank you!</h3>
                                <p>
                                    Your consultation call is scheduled at your chosen time. We look forward to speaking with you.
                                </p>
                            </div>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default BookaConsultation;
