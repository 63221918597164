/* global gtag */
import { Button, Form, Modal } from "react-bootstrap";
import { useState } from 'react';
import RequestCall from '../../assets/images/get-report-left-graphic.svg';
import "./style.css";

function GetaFreeReport() {
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        students: '',
        institution: '',
        attendanceMethod: '',
        attendanceTime: '',
        email: '',
        lecturesPerDay: ''
    });
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const handleClose = () => {
        setShow(false);
        setSubmitted(false); // Reset after closing
        setFormData({ students: '', institution: '', attendanceMethod: '', attendanceTime: '', email: '', lecturesPerDay: '' });
        setErrors({});
    };

    const handleShow = () => setShow(true);

    const validate = () => {
        let newErrors = {};
        if (!formData.students) newErrors.students = "Number of students is required";
        if (!formData.institution) newErrors.institution = "Institution name is required";
        if (!formData.attendanceMethod) newErrors.attendanceMethod = "Please select an attendance method";
        if (!formData.attendanceTime) newErrors.attendanceTime = "Please select attendance time";
        if (!formData.email) {
            newErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Email is invalid";
        }
        if (!formData.lecturesPerDay) newErrors.lecturesPerDay = "Enter number of lectures per day";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setSubmitted(true);
        }
        gtag('event', 'custom_report_submit');
    };

    const handleButtonClick = () => {
        // Send custom event to gtag

            gtag('event', 'custom_report_click');
        handleShow();
    };

    return (
        <>
            <div className='Get_Free_Report'>
                <div className='container'>
                    <div className='cta_banner_wrapper'>
                        <div className='cta_banner_content'>
                            <h2>Discover Your Attendance Efficiency</h2>
                            <p>What's in the Report?</p>
                            <ul>
                                <li><strong>Time Loss</strong>: Annual estimates of time wasted on attendance</li>
                                <li><strong>Cost Impact</strong>: Calculation of salary hours lost due to manual roll calls.</li>
                                <li><strong>Benchmark Comparison</strong>: Your institution vs. NAAC efficiency standards.</li>
                            </ul>
                        </div>
                        <button className='call_back_button' onClick={handleButtonClick}>Get a Free Report</button>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} size="xl" centered className="Second_cta_forms_modal AttendanceProcess">            
                <Modal.Body>
                    <div className="graphic_side">
                        <img src={RequestCall} alt="Request Call" />
                    </div>

                    <div className="form_side">
                        <Modal.Header closeButton>
                            <Modal.Title>Optimise Your Attendance Process <span>Get a Free Report</span></Modal.Title>
                        </Modal.Header>

                        {!submitted ? (
                            <Form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group className="mb-3">
                                            <Form.Control
                                                type="text"
                                                placeholder="Number of Students"
                                                value={formData.students}
                                                onChange={(e) => setFormData({ ...formData, students: e.target.value })}
                                            />
                                            {errors.students && <span className="error text-danger"><small>{errors.students}</small></span>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group className="mb-3">
                                            <Form.Control
                                                type="text"
                                                placeholder="Institution Name"
                                                value={formData.institution}
                                                onChange={(e) => setFormData({ ...formData, institution: e.target.value })}
                                            />
                                            {errors.institution && <span className="error text-danger"><small>{errors.institution}</small></span>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <Form.Select
                                            value={formData.attendanceMethod}
                                            onChange={(e) => setFormData({ ...formData, attendanceMethod: e.target.value })}
                                        >
                                            <option value="">Current Attendance Method</option>
                                            <option value="Manual">Manual</option>
                                            <option value="Biometric">Biometric</option>
                                            <option value="Digital">Digital</option>
                                        </Form.Select>
                                        {errors.attendanceMethod && <span className="error text-danger"><small>{errors.attendanceMethod}</small></span>}
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <Form.Select
                                            value={formData.attendanceTime}
                                            onChange={(e) => setFormData({ ...formData, attendanceTime: e.target.value })}
                                        >
                                            <option value="">How Long Does Attendance Take Per Class?</option>
                                            <option value="5 min">5 min</option>
                                            <option value="10 min">10 min</option>
                                            <option value="15 min">15 min</option>
                                        </Form.Select>
                                        {errors.attendanceTime && <span className="error text-danger"><small>{errors.attendanceTime}</small></span>}
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group className="mb-3">
                                            <Form.Control
                                                type="email"
                                                placeholder="Email to Receive Report"
                                                value={formData.email}
                                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                            />
                                            {errors.email && <span className="error text-danger"><small>{errors.email}</small></span>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group className="mb-4">
                                            <Form.Control
                                                type="text"
                                                placeholder="How Many Lectures Per Day?"
                                                value={formData.lecturesPerDay}
                                                onChange={(e) => setFormData({ ...formData, lecturesPerDay: e.target.value })}
                                            />
                                            {errors.lecturesPerDay && <span className="error text-danger"><small>{errors.lecturesPerDay}</small></span>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <Button type="submit" variant="primary">Submit</Button>
                            </Form>
                        ) : (
                            <div className="after_submit_msg">
                                <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M54.77 34.2702C55.7708 33.2696 55.7708 31.6471 54.77 30.6464C53.7693 29.6457 52.147 29.6457 51.1463 30.6464L35.8748 45.9176L30.8535 40.8965C29.8527 39.8958 28.2302 39.8958 27.2295 40.8965C26.2288 41.8972 26.2288 43.5195 27.2295 44.5202L34.0629 51.3536C35.0637 52.3543 36.6859 52.3543 37.6867 51.3536L54.77 34.2702Z" fill="#00CA78"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M41.0002 4.27081C20.7152 4.27081 4.271 20.715 4.271 41C4.271 61.2851 20.7152 77.7291 41.0002 77.7291C61.2853 77.7291 77.7293 61.2851 77.7293 41C77.7293 20.715 61.2853 4.27081 41.0002 4.27081ZM9.396 41C9.396 23.5455 23.5457 9.39581 41.0002 9.39581C58.4545 9.39581 72.6043 23.5455 72.6043 41C72.6043 58.4544 58.4545 72.6041 41.0002 72.6041C23.5457 72.6041 9.396 58.4544 9.396 41Z" fill="#00CA78"/>
                                </svg>
                                <h3>Thank you! </h3>
                                <p>Your Attendance Efficiency Report is on its way to your inbox.</p>
                            </div>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default GetaFreeReport;