
/* global gtag */
import React, { useState } from 'react';
import { Link,useLocation } from 'react-router-dom';
import { LoginPanel } from './loginPanel'; // Named export - add curly braces
import freeimg from '../../assets/images/free-img.png';
import './price.css';
import CalculatePriceCTA from '../../components/CalculatePriceCTA';
import { Helmet } from "react-helmet";


function Pricing() {
  const location = useLocation(); // Get the current path
  const [panelVisible, setPanelVisible] = useState(false);

  const closePanel = () => {
    setPanelVisible(false); // Explicitly close the panel
  };

  const trackEvent = (elementId) => {
    const currentPath = location.pathname.replace("/", "") || "pricing";
    const keyword = "page"; // Use 'footer' for Footer navigation
    const eventName = `${currentPath}_${keyword}_${elementId}_click`;
    gtag("event", eventName);
  };

  // Trigger GA4 events when navigation links are clicked
  const handleLinkClick = (to, elementId) => {
    trackEvent(elementId);
  };

  return (
    <>
      <Helmet>
        <title>Pricing - Affordable Attendance Solutions</title>
        <meta name="h1" content="Pricing" />
        <meta
          name="h3"
          content="Affordable Attendance Solutions for Your Institution"
        />
        <meta name="h3" content="₹40 Per student/month" />
        <meta
          name="h4"
          content="Want to see it in action first? Schedule a free Call. Enhance Your Experience Have more than 50 students? Early Bird Offer - FREE"
        />
      </Helmet>
      <div className="innerpage-headerbanner">
        <h1>Pricing</h1>
      </div>


      <div className='price-page-wrap'>

        <CalculatePriceCTA/>



        <h3 className='price-heading'>

          Affordable Attendance Solutions for Your <strong>Institution</strong>
        </h3>

        <div className="price-page-card">
          <div className="price-card-header">
            <h3>
              <span>₹40</span> Per student/month
            </h3>
            <Link
              to="https://uat-admin.myrollcall.pro/"
              id="EnrollNow"
              className="btn EnrollNow"
              onClick={() => handleLinkClick('/enroll_now', 'enroll_now')}
            >
              Enroll Now
            </Link>
            <h4>
              Want to see it in action first?{" "}
              <Link id="demo_request" to="/demo_request">
                Schedule a free Call.
              </Link>
            </h4>
            <div className="freecard">
              <img src={freeimg} alt="Free Offer" />
              <div className="free-content">
                <h2>
                  Early Bird Offer - <strong>FREE</strong>
                </h2>
                <p>
                  Unlock access for the first <strong>50 students</strong> at{" "}
                  <strong>Zero</strong> cost.
                </p>
              </div>
            </div>
          </div>

          <div className="students-card">
            <h5>Have more than 50 students? </h5>
            <p>
              Contact us for discounted pricing tailored to your institution’s
              needs.
            </p>
            <div className="students-buttons">
              <Link
                id="demo_request"
                to="/demo_request"
                className="btn RequestaDemo"
                onClick={() => handleLinkClick('/demo_request', 'demo_request')}
              >
                Request a Demo
              </Link>
              <Link
                id="get_started"
                to="https://uat-admin.myrollcall.pro/"
                className="btn GetStarted"
                onClick={() => handleLinkClick('/get_started', 'get_started')}
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>

        {/* Pass closePanel as a prop to the LoginPanel */}
        {panelVisible && (
          <LoginPanel className="custom-login-panel" onClose={closePanel} />
        )}

        <div className="EnhanceYourExperience">
          <div className="headings text-center">
            <h2>Enhance Your Experience</h2>
            <p>
              Unlock additional capabilities with our optional add-ons,{" "}
              <span>available after enrollment.</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pricing;
