import React from 'react'
import { Link } from 'react-router-dom';
import appImg from '../../assets/images/app-image.png';
import './style.css';

function FooterCTA() {
    return (
        <section className='section'>
            <div className='container'>
                <div className='footercta'>
                    <div className='app-img'><img src={appImg} /></div>
                    <div className='cta-right-content'>
                        <h2>Elevate Your Attendance Management Software Today</h2>
                        <p>Unlock the full potential of MyRollCall.Pro Your E Attendance App</p>
                        <div className='cta-btns-grp'>
                            <Link to="/bookademo" className="globel-button">
                                <div className='txt-tooltip'>
                                    Experience the benefits firsthand and see how our solution fits your needs.
                                </div>
                                <span>Request a Demo</span>
                                <i><svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 6L16 6M16 6L11 1M16 6L11 11" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg></i>
                            </Link>
                            <button className="globel-button border-white">
                                <div className='txt-tooltip'>
                                    Join the revolution in attendance tracking system and start optimising your institution’s operations now.
                                </div>
                                <span className='pricing-span'>
                                    <Link to='/pricing' className='view-pricing-link'>View Pricing</Link>
                                </span>

                                <i><svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 6L16 6M16 6L11 1M16 6L11 11" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FooterCTA

